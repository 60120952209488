/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DynamicFiling,
  Filing,
  FilingDetails,
  PaginatedFilingDetailsList,
  PatchedDynamicFiling,
} from '../models/index';
import {
    DynamicFilingFromJSON,
    DynamicFilingToJSON,
    FilingFromJSON,
    FilingToJSON,
    FilingDetailsFromJSON,
    FilingDetailsToJSON,
    PaginatedFilingDetailsListFromJSON,
    PaginatedFilingDetailsListToJSON,
    PatchedDynamicFilingFromJSON,
    PatchedDynamicFilingToJSON,
} from '../models/index';

export interface FilingsCreateRequest {
    teamSlug: string;
    filing: Omit<Filing, 'id'|'filing_status_display'|'jurisdiction_display'|'payment_status_display'|'report_name_display'|'created_at'|'updated_at'|'team'>;
}

export interface FilingsDestroyRequest {
    id: number;
    teamSlug: string;
}

export interface FilingsListRequest {
    teamSlug: string;
    page?: number;
}

export interface FilingsPartialUpdateRequest {
    id: number;
    teamSlug: string;
    patchedDynamicFiling?: Omit<PatchedDynamicFiling, 'id'|'created_at'|'updated_at'>;
}

export interface FilingsRetrieveRequest {
    id: number;
    teamSlug: string;
}

export interface FilingsUpdateRequest {
    id: number;
    teamSlug: string;
    dynamicFiling: Omit<DynamicFiling, 'id'|'created_at'|'updated_at'>;
}

export interface GetFilingToProcessRequest {
    reportName: string;
}

/**
 * FilingsApi - interface
 * 
 * @export
 * @interface FilingsApiInterface
 */
export interface FilingsApiInterface {
    /**
     * 
     * @param {string} teamSlug 
     * @param {Filing} filing 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilingsApiInterface
     */
    filingsCreateRaw(requestParameters: FilingsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Filing>>;

    /**
     */
    filingsCreate(requestParameters: FilingsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Filing>;

    /**
     * 
     * @param {number} id A unique integer value identifying this filing.
     * @param {string} teamSlug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilingsApiInterface
     */
    filingsDestroyRaw(requestParameters: FilingsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     */
    filingsDestroy(requestParameters: FilingsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @param {string} teamSlug 
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilingsApiInterface
     */
    filingsListRaw(requestParameters: FilingsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedFilingDetailsList>>;

    /**
     */
    filingsList(requestParameters: FilingsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedFilingDetailsList>;

    /**
     * 
     * @param {number} id A unique integer value identifying this filing.
     * @param {string} teamSlug 
     * @param {PatchedDynamicFiling} [patchedDynamicFiling] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilingsApiInterface
     */
    filingsPartialUpdateRaw(requestParameters: FilingsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DynamicFiling>>;

    /**
     */
    filingsPartialUpdate(requestParameters: FilingsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DynamicFiling>;

    /**
     * 
     * @param {number} id A unique integer value identifying this filing.
     * @param {string} teamSlug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilingsApiInterface
     */
    filingsRetrieveRaw(requestParameters: FilingsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FilingDetails>>;

    /**
     */
    filingsRetrieve(requestParameters: FilingsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FilingDetails>;

    /**
     * 
     * @param {number} id A unique integer value identifying this filing.
     * @param {string} teamSlug 
     * @param {DynamicFiling} dynamicFiling 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilingsApiInterface
     */
    filingsUpdateRaw(requestParameters: FilingsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DynamicFiling>>;

    /**
     */
    filingsUpdate(requestParameters: FilingsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DynamicFiling>;

    /**
     * Retrieve the oldest filing that is ready for processing based on the report type
     * @param {string} reportName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilingsApiInterface
     */
    getFilingToProcessRaw(requestParameters: GetFilingToProcessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: any; }>>;

    /**
     * Retrieve the oldest filing that is ready for processing based on the report type
     */
    getFilingToProcess(requestParameters: GetFilingToProcessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: any; }>;

}

/**
 * 
 */
export class FilingsApi extends runtime.BaseAPI implements FilingsApiInterface {

    /**
     */
    async filingsCreateRaw(requestParameters: FilingsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Filing>> {
        if (requestParameters['teamSlug'] == null) {
            throw new runtime.RequiredError(
                'teamSlug',
                'Required parameter "teamSlug" was null or undefined when calling filingsCreate().'
            );
        }

        if (requestParameters['filing'] == null) {
            throw new runtime.RequiredError(
                'filing',
                'Required parameter "filing" was null or undefined when calling filingsCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/a/{team_slug}/filings/api/filings/`.replace(`{${"team_slug"}}`, encodeURIComponent(String(requestParameters['teamSlug']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FilingToJSON(requestParameters['filing']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FilingFromJSON(jsonValue));
    }

    /**
     */
    async filingsCreate(requestParameters: FilingsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Filing> {
        const response = await this.filingsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async filingsDestroyRaw(requestParameters: FilingsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling filingsDestroy().'
            );
        }

        if (requestParameters['teamSlug'] == null) {
            throw new runtime.RequiredError(
                'teamSlug',
                'Required parameter "teamSlug" was null or undefined when calling filingsDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/a/{team_slug}/filings/api/filings/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))).replace(`{${"team_slug"}}`, encodeURIComponent(String(requestParameters['teamSlug']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async filingsDestroy(requestParameters: FilingsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.filingsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async filingsListRaw(requestParameters: FilingsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedFilingDetailsList>> {
        if (requestParameters['teamSlug'] == null) {
            throw new runtime.RequiredError(
                'teamSlug',
                'Required parameter "teamSlug" was null or undefined when calling filingsList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/a/{team_slug}/filings/api/filings/`.replace(`{${"team_slug"}}`, encodeURIComponent(String(requestParameters['teamSlug']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedFilingDetailsListFromJSON(jsonValue));
    }

    /**
     */
    async filingsList(requestParameters: FilingsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedFilingDetailsList> {
        const response = await this.filingsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async filingsPartialUpdateRaw(requestParameters: FilingsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DynamicFiling>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling filingsPartialUpdate().'
            );
        }

        if (requestParameters['teamSlug'] == null) {
            throw new runtime.RequiredError(
                'teamSlug',
                'Required parameter "teamSlug" was null or undefined when calling filingsPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/a/{team_slug}/filings/api/filings/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))).replace(`{${"team_slug"}}`, encodeURIComponent(String(requestParameters['teamSlug']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedDynamicFilingToJSON(requestParameters['patchedDynamicFiling']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DynamicFilingFromJSON(jsonValue));
    }

    /**
     */
    async filingsPartialUpdate(requestParameters: FilingsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DynamicFiling> {
        const response = await this.filingsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async filingsRetrieveRaw(requestParameters: FilingsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FilingDetails>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling filingsRetrieve().'
            );
        }

        if (requestParameters['teamSlug'] == null) {
            throw new runtime.RequiredError(
                'teamSlug',
                'Required parameter "teamSlug" was null or undefined when calling filingsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/a/{team_slug}/filings/api/filings/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))).replace(`{${"team_slug"}}`, encodeURIComponent(String(requestParameters['teamSlug']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FilingDetailsFromJSON(jsonValue));
    }

    /**
     */
    async filingsRetrieve(requestParameters: FilingsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FilingDetails> {
        const response = await this.filingsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async filingsUpdateRaw(requestParameters: FilingsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DynamicFiling>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling filingsUpdate().'
            );
        }

        if (requestParameters['teamSlug'] == null) {
            throw new runtime.RequiredError(
                'teamSlug',
                'Required parameter "teamSlug" was null or undefined when calling filingsUpdate().'
            );
        }

        if (requestParameters['dynamicFiling'] == null) {
            throw new runtime.RequiredError(
                'dynamicFiling',
                'Required parameter "dynamicFiling" was null or undefined when calling filingsUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/a/{team_slug}/filings/api/filings/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))).replace(`{${"team_slug"}}`, encodeURIComponent(String(requestParameters['teamSlug']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DynamicFilingToJSON(requestParameters['dynamicFiling']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DynamicFilingFromJSON(jsonValue));
    }

    /**
     */
    async filingsUpdate(requestParameters: FilingsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DynamicFiling> {
        const response = await this.filingsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve the oldest filing that is ready for processing based on the report type
     */
    async getFilingToProcessRaw(requestParameters: GetFilingToProcessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: any; }>> {
        if (requestParameters['reportName'] == null) {
            throw new runtime.RequiredError(
                'reportName',
                'Required parameter "reportName" was null or undefined when calling getFilingToProcess().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/api/get-filing-to-process/{report_name}/`.replace(`{${"report_name"}}`, encodeURIComponent(String(requestParameters['reportName']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Retrieve the oldest filing that is ready for processing based on the report type
     */
    async getFilingToProcess(requestParameters: GetFilingToProcessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: any; }> {
        const response = await this.getFilingToProcessRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
